const number = 1;

// function header scroll

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  var home = document.getElementById("home");
  var header = document.getElementById("headerHome");
  var d1 = document.getElementById("drop1");
  var d2 = document.getElementById("drop2");

  if (currentScrollPos !== 0 && home) {
    header.className = "header-white";

  } else if (prevScrollpos > currentScrollPos && home) {
    header.className = "header-transparent";
    d1.className = "dropdown-menu";
    d2.className = "dropdown-menu";
  }
  prevScrollpos = currentScrollPos;
}

// function dropdown header white

function drop() {
  var x = document.getElementById("headerHome");
  if (x.classList === "header-white") {
    x.classList.toogle("header-white");
  }
}

/* ======================== scrollspy angular ======================== */


function openNav() {
  document.getElementById("headerMobile").style.width = "100%";
  document.getElementById("sideBar").style.opacity = "1";
}

function closeNav() {
  document.getElementById("headerMobile").style.width = "0";
  document.getElementById("sideBar").style.opacity = "0";
}
